<template>
    <div class="d-flex flex-column flex-root">
        <!--begin::Login-->
        <div class="login login-2 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
             :class="{
                'login-signin-on': this.state == 'signin',
                'login-signup-on': this.state == 'signup',
                'login-forgot-on': this.state == 'forgot'
              }"
             id="kt_login">
            <!--begin::Aside-->
            <div class="login-aside order-2 order-lg-1 d-flex flex-column-fluid flex-lg-row-auto bgi-size-cover bgi-no-repeat p-7 p-lg-10">
                <!--begin: Aside Container-->
                <div class="d-flex flex-row-fluid flex-column justify-content-between">
                    <!--begin::Aside body-->
                    <div class="d-flex flex-column-fluid flex-column flex-center mt-5 mt-lg-0">
                        <a href="#" class="mb-6 text-center">
                            <img src="media/logos/logo-letter-1.png" class="max-h-120px" alt="">
                        </a>
                        <!--begin::Signin-->
                        <div class="login-form login-signin">
                            <div class="text-center mb-10 mb-lg-20">
                                <h1 class="font-weight-bold">用户登录</h1>
                                <p></p>
                                <div class="card card-custom">
                                    <div class="card-header card-header-tabs-line">
                                        <div class="card-toolbar">
                                            <ul class="nav nav-tabs nav-bold nav-tabs-line">
                                                <li class="nav-item">
                                                    <a class="nav-link" :class="{'active': this.tab == 'account_login'}" data-toggle="tab" @click="showTab('account_login')">账号登录</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a class="nav-link" :class="{'active': this.tab == 'sms_login'}"  data-toggle="tab" @click="showTab('sms_login')">短信登录</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="tab-content">
                                            <div class="tab-pane fade" :class="{'active show': this.tab == 'account_login'}" id="kt_tab_pane_1_2" role="tabpanel">
                                                <!--begin::Form-->
                                                <form class="form fv-plugins-bootstrap fv-plugins-framework" novalidate="novalidate" id="kt_login_signin_account_form">
                                                    <div class="form-group py-3 m-0">
                                                        <div class="input-icon">
                                                            <input type="text" name="username" v-model="username" class="form-control" placeholder="请输入账号">
                                                            <span>
																<i class="la la-user-tie icon-md"></i>
															</span>
                                                        </div>
                                                    </div>
                                                    <div class="form-group py-3 ">
                                                        <div class="input-icon">
                                                            <input type="password" name="password" v-model="password" class="form-control" placeholder="请输入密码">
                                                            <span>
																<i class="la la-lock icon-md"></i>
															</span>
                                                        </div>
                                                    </div>
                                                    <div class="form-group d-flex flex-wrap justify-content-between align-items-center mt-3">
                                                        <div class="checkbox-inline">
                                                            <label class="checkbox checkbox-outline m-0 text-muted">
                                                                <input type="checkbox" name="remember" v-model="remember">
                                                                <span></span>记住密码</label>
                                                        </div>
                                                        <a @click="showForm('forgot')" class="text-muted text-hover-primary">忘记密码 ?</a>
                                                    </div>
                                                    <div class="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
                                                        <div class="my-3 mr-2">
                                                            <span class="text-muted mr-2">还没有账号 ?</span>
                                                            <a @click="showForm('signup')" id="kt_login_signup" class="font-weight-bold">注册开通</a>
                                                        </div>
                                                        <button ref="kt_login_signin_account_submit" class="btn btn-primary font-bold px-9 py-4 my-3">登 录</button>
                                                    </div>
                                                    <input type="hidden"><div></div></form>
                                                <!--end::Form-->
                                            </div>
                                            <div class="tab-pane fade" :class="{'active show': this.tab == 'sms_login'}" id="kt_tab_pane_2_2" role="tabpanel">
                                                <!--begin::Form-->
                                                <form class="form" novalidate="novalidate" id="kt_login_signin_sms_form">
                                                    <div class="form-group py-3 m-0">
                                                        <div class="input-icon">
                                                            <input type="text" name="loginphone" v-model="loginphone" class="form-control" placeholder="请输入手机号">
                                                            <span>
																<i class="la la-mobile icon-md"></i>
															</span>
                                                        </div>
                                                    </div>
                                                    <div class="form-group py-3 ">
                                                        <div class="input-icon">
                                                            <input type="text" name="loginsmscode" v-model="loginsmscode" class="form-control" placeholder="请输入校验码" style="background-position: right calc(5.375em + 0.325rem) center;">
                                                            <span>
																<i class="la la-code icon-md"></i>
															</span>
                                                            <button type="button" @click="getSmsCode(1)">获取验证码</button>
                                                        </div>
                                                    </div>
                                                    <div class="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
                                                        <div class="my-3 mr-2">
                                                            <span class="text-muted mr-2">还没有账号 ?</span>
                                                            <a @click="showForm('signup')" id="kt_login_signup1" class="font-weight-bold">注册开通</a>
                                                        </div>
                                                        <button ref="kt_login_signin_sms_submit" class="btn btn-primary font-bold px-9 py-4 my-3">登 录</button>
                                                    </div>
                                                </form>
                                                <!--end::Form-->
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--end::Signin-->

                        <!--begin::Signup-->
                        <div class="login-form login-signup">
                            <div class="text-center mb-10 mb-lg-20">
                                <h1 class="font-weight-bold">用户注册</h1>
                                <p></p>
                            </div>
                            <!--begin::Form-->
                            <form class="form fv-plugins-bootstrap fv-plugins-framework" novalidate="novalidate" id="kt_login_signup_form">
                                <div class="form-group py-3 m-0">
                                    <div class="input-icon">
                                        <input type="text" class="form-control" name="rusername" v-model="registerusername" placeholder="请输入账号">
                                        <span>
											<i class="la la-user-tie icon-md"></i>
										</span>
                                    </div>
                                </div>
                                <div class="form-group py-3 m-0">
                                    <div class="input-icon">
                                        <input type="text" class="form-control" name="rphone" v-model="registerphone" placeholder="请输入手机号">
                                        <span>
											<i class="la la-mobile icon-md"></i>
										</span>
                                    </div>
                                </div>
                                <div class="form-group py-3 m-0">
                                    <div class="input-icon">
                                        <input type="text" name="rcode" v-model="registercode" class="form-control" placeholder="请输入校验码" style="background-position: right calc(5.375em + 0.325rem) center;">
                                        <span>
											<i class="la la-code icon-md"></i>
										</span>
                                        <button type="button" @click="getSmsCode(2)">获取验证码</button>
                                    </div>
                                </div>
                                <div class="form-group py-3 m-0">
                                    <div class="input-icon">
                                        <input type="password" class="form-control" name="rpassword" v-model="registerpassword" placeholder="请输入密码">
                                        <span>
											<i class="la la-lock icon-md"></i>
										</span>
                                    </div>
                                </div>
                                <div class="form-group py-3 m-0">
                                    <div class="input-icon">
                                        <input type="password" class="form-control" name="spassword" v-model="registerspassword" placeholder="请确认密码">
                                        <span>
											<i class="la la-unlock-alt icon-md"></i>
										</span>
                                    </div>
                                </div>

                                <div class="form-group d-flex flex-wrap flex-center">
                                    <button ref="kt_login_signup_submit" class="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-2">确认注册</button>
                                    <button ref="kt_login_signup_cancel" type="button" class="btn btn-outline-primary font-weight-bold px-9 py-4 my-3 mx-2" @click="showForm('signin')">取消</button>
                                </div>
                                <div></div></form>
                            <!--end::Form-->
                        </div>
                        <!--end::Signup-->


                        <!--begin::Forgot-->
                        <div class="login-form login-forgot">
                            <div class="text-center mb-10 mb-lg-20">
                                <h1 class="font-weight-bold">忘记密码</h1>
                                <p></p>
                            </div>
                            <!--begin::Form-->
                            <form class="form fv-plugins-bootstrap fv-plugins-framework" novalidate="novalidate" id="kt_login_forgot_form">

                                <div class="form-group py-3 m-0">
                                    <div class="input-icon">
                                        <input type="text" class="form-control" name="fphone" v-model="forgotphone" placeholder="请输入手机号">
                                        <span>
											<i class="la la-mobile icon-md"></i>
										</span>
                                    </div>
                                </div>
                                <div class="form-group py-3 m-0">
                                    <div class="input-icon">
                                        <input type="text" name="fcode" v-model="forgotcode" class="form-control" placeholder="请输入校验码" style="background-position: right calc(5.375em + 0.325rem) center;">
                                        <span>
											<i class="la la-code icon-md"></i>
										</span>
                                        <button type="button" @click="getSmsCode(3)">获取验证码</button>
                                    </div>
                                </div>
                                <div class="form-group py-3 m-0">
                                    <div class="input-icon">
                                        <input type="password" class="form-control" name="fpassword" v-model="forgotpassword" placeholder="请输入密码">
                                        <span>
											<i class="la la-lock icon-md"></i>
										</span>
                                    </div>
                                </div>
                                <div class="form-group py-3 m-0">
                                    <div class="input-icon">
                                        <input type="password" class="form-control" name="fspassword" v-model="forgotspassword" placeholder="请确认密码">
                                        <span>
											<i class="la la-unlock-alt icon-md"></i>
										</span>
                                    </div>
                                </div>

                                <div class="form-group d-flex flex-wrap flex-center">
                                    <button ref="kt_login_forgot_submit" class="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-2">提交</button>
                                    <button ref="kt_login_forgot_cancel" type="button" class="btn btn-outline-primary font-weight-bold px-9 py-4 my-3 mx-2" @click="showForm('signin')">取消</button>
                                </div>
                                <div></div></form>
                            <!--end::Form-->
                        </div>
                        <!--end::Forgot-->

                    </div>
                    <!--end::Aside body-->
                    <!--begin: Aside footer for desktop-->
                    <div class="d-flex flex-column-auto justify-content-between mt-15">
                        <div class="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">© 2021 Cpssm进销存系统</div>

                    </div>
                    <!--end: Aside footer for desktop-->
                </div>
                <!--end: Aside Container-->
            </div>
            <!--begin::Aside-->
            <!--begin::Content-->
            <div class="order-1 order-lg-2 flex-column-auto flex-lg-row-fluid d-flex flex-column p-7" style="background-image: url(media/bg/bg-4.jpg);background-repeat: no-repeat;background-size: cover;">
                <!--begin::Content body-->
                <div class="d-flex flex-column-fluid flex-lg-center">
                    <div class="d-flex flex-column justify-content-center">
                        <h3 class="display-3 font-weight-bold my-7 text-white">欢迎登录百舟海外电商进销存系统 !</h3>
                        <p class="font-weight-bold font-size-lg text-white opacity-80">全方位保障您的资金安全，同时拥有境内外支付牌照
                            <br>保障资金合规，安全无风险
                        </p>
                    </div>
                </div>
                <!--end::Content body-->
            </div>
            <!--end::Content-->
        </div>
        <!--end::Login-->
    </div>
</template>
<style lang="css">
    @import "~@/assets/css/pages/login/login-2.css";

    .fv-plugins-message-container{
        text-align:left;
        padding-left:10px;
    }
</style>
<script>
    import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

    // FormValidation plugins
    import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
    import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
    import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
    import KTUtil from "@/assets/js/components/util";
    import {mapGetters, mapState} from "vuex";


    import {login, register, getSmsCode, updatePwd, phoneLogin} from '@/api/user';

    const global = require('../../../../config/global.env');

    export default {
        name: "login",
        data() {

            //判断记住密码
            let cache_key = global.APP_NAME + '_remember_account';
            let isremember = localStorage.getItem(cache_key + '_isremember');
            let pwd = '';
            if(isremember == 'true'){
                isremember = true;
                pwd = localStorage.getItem(cache_key + '_password');
            } else {
                isremember = false;
            }
            let name = localStorage.getItem(cache_key + '_username');

            return {
                state: "signin",
                tab: "account_login",
                username: name,
                password: pwd,
                remember: isremember,
                loginphone: '',
                loginsmscode: '',
                registerusername: '',
                registerphone: '',
                registercode: '',
                registerpassword: '',
                registerspassword: '',
                forgotphone: '',
                forgotcode: '',
                forgotpassword: '',
                forgotspassword: ''
            }
        },
        mounted() {
            const account_signin_form = KTUtil.getById("kt_login_signin_account_form");
            const sms_signin_form = KTUtil.getById("kt_login_signin_sms_form");
            const signup_form = KTUtil.getById("kt_login_signup_form");
            const forgot_form = KTUtil.getById("kt_login_forgot_form");

            //账号登录表单验证
            this.fv = formValidation(account_signin_form, {
                fields: {
                    username: {
                        validators: {
                            notEmpty: {
                                message: "登录账号不能为空"
                            }
                        }
                    },
                    password: {
                        validators: {
                            notEmpty: {
                                message: "登录密码不能为空"
                            }
                        }
                    }
                },
                plugins: {
                    trigger: new Trigger(),
                    submitButton: new SubmitButton(),
                    bootstrap: new Bootstrap()
                }
            });

            //账号登录提交事件
            this.fv.on("core.form.valid", () => {
                var _this = this;
                //登录按钮加载动画
                const submitButton = this.$refs["kt_login_signin_account_submit"];
                submitButton.classList.add("spinner", "spinner-light", "spinner-right");

                //登录功能
                login({'username': _this.username, 'password': _this.password}).then(function (res) {
                    //登录成功
                    if (res) {
                        //缓存token登录秘钥
                        localStorage.setItem(global.APP_NAME + '_Authorization_Token', res.response.token);
                        //是否记住密码
                        let cache_key = global.APP_NAME + '_remember_account';
                        if(_this.remember){
                            localStorage.setItem(cache_key + '_password', _this.password);
                        } else {
                            localStorage.removeItem(cache_key + '_password');
                        }
                        localStorage.setItem(cache_key + '_isremember', _this.remember);
                        localStorage.setItem(cache_key + '_username', _this.username);
                        //跳转到登录页
                        _this.$router.push('/');
                    } else {
                        //记录登录失败次数
                        //_this.verify_visible = true;
                    }
                    submitButton.classList.remove(
                        "spinner",
                        "spinner-light",
                        "spinner-right"
                    );
                });

            });

            //短信登录表单验证
            this.fv1 = formValidation(sms_signin_form, {
                fields: {
                    loginphone: {
                        validators: {
                            notEmpty: {
                                message: "手机号码不能为空"
                            },
                            regexp: {
                                regexp: /^1\d{10}$/,
                                message: '手机号格式错误'
                            }
                        }
                    },
                    loginsmscode: {
                        validators: {
                            notEmpty: {
                                message: "短信验证码不能为空"
                            }
                        }
                    }
                },
                plugins: {
                    trigger: new Trigger(),
                    submitButton: new SubmitButton(),
                    bootstrap: new Bootstrap()
                }
            });

            //短信登录提交事件
            this.fv1.on("core.form.valid", () => {
                var _this = this;
                //登录按钮加载动画
                const submitButton = this.$refs["kt_login_signin_sms_submit"];
                submitButton.classList.add("spinner", "spinner-light", "spinner-right");

                //登录功能
                phoneLogin({'phone': _this.loginphone, 'code': _this.loginsmscode}).then(function (res) {
                    //登录成功
                    if (res) {
                        //缓存token登录秘钥
                        localStorage.setItem(global.APP_NAME + '_Authorization_Token', res.response.token);

                        //跳转到登录页
                        _this.$router.push('/');
                    } else {
                        //记录登录失败次数
                        //_this.verify_visible = true;
                    }
                    submitButton.classList.remove(
                        "spinner",
                        "spinner-light",
                        "spinner-right"
                    );
                });

            });

            //账号注册表单验证
            this.fv2 = formValidation(signup_form, {
                fields: {
                    rusername: {
                        validators: {
                            notEmpty: {
                                message: "登录账号不能为空"
                            },
                            stringLength: {
                                min: 3,
                                message: '用户名长度必须大于等于3个字符'
                            },
                            regexp: {
                                regexp: /^[\u4e00-\u9fa5a-zA-Z0-9]+$/,
                                message: '用户名由3-50位中英文、数字组成'
                            }
                        }
                    },
                    rphone: {
                        validators: {
                            notEmpty: {
                                message: "手机号码不能为空"
                            },
                            regexp: {
                                regexp: /^1\d{10}$/,
                                message: '手机号格式错误'
                            }
                        }
                    },
                    rcode: {
                        validators: {
                            notEmpty: {
                                message: "短信验证码不能为空"
                            }

                        }
                    },
                    rpassword: {
                        validators: {
                            notEmpty: {
                                message: "密码不能为空"
                            },
                            stringLength: {
                                min: 8,
                                message: '密码长度必须大于等于8个字符'
                            },
                            regexp: {
                                regexp: /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?![a-zA-Z]+$)(?![0-9a-z]+$)(?![0-9A-Z]+$)[0-9A-Za-z]{8,}$/,
                                message: '密码由至少8位大小写字母，数字组成，不能包含特殊符号'
                            },
                            regexp: {
                                regexp: /^(?=[0-9a-zA-Z]+$)/,
                                message: '密码由至少8位大小写字母，数字组成，不能包含特殊符号'
                            }
                        }
                    },
                    spassword: {
                        validators: {
                            notEmpty: {
                                message: "确认密码不能为空"
                            },
                            identical: {
                                compare: function () {
                                    return signup_form.querySelector('[name="spassword"]').value;
                                },
                                message: "两次密码不一致"
                            }
                        }
                    },
                },
                plugins: {
                    trigger: new Trigger(),
                    submitButton: new SubmitButton(),
                    bootstrap: new Bootstrap()
                }
            });

            //账号注册表单提交
            this.fv2.on("core.form.valid", () => {
                var _this = this;
                //登录按钮加载动画
                const submitButton = this.$refs["kt_login_signup_submit"];
                submitButton.classList.add("spinner", "spinner-light", "spinner-right");

                //用户注册
                register({'username': _this.registerusername, 'password': _this.registerpassword, 'phone': _this.registerphone, 'code': _this.registercode}).then(function (res) {
                    if (res) {
                        KTUtil.alert('', res.message, 'success');
                        _this.showForm('signin');
                        signup_form.reset();
                    }
                    submitButton.classList.remove(
                        "spinner",
                        "spinner-light",
                        "spinner-right"
                    );
                });
            });

            //忘记密码表单验证
            this.fv3 = formValidation(forgot_form, {
                fields: {
                    fphone: {
                        validators: {
                            notEmpty: {
                                message: "手机号码不能为空"
                            },
                            regexp: {
                                regexp: /^1\d{10}$/,
                                message: '手机号格式错误'
                            }
                        }
                    },
                    fcode: {
                        validators: {
                            notEmpty: {
                                message: "短信验证码不能为空"
                            }

                        }
                    },
                    fpassword: {
                        validators: {
                            notEmpty: {
                                message: "密码不能为空"
                            },
                            stringLength: {
                                min: 8,
                                message: '密码长度必须大于等于8个字符'
                            },
                            regexp: {
                                regexp: /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?![a-zA-Z]+$)(?![0-9a-z]+$)(?![0-9A-Z]+$)[0-9A-Za-z]{8,}$/,
                                message: '密码由至少8位大小写字母，数字组成，不能包含特殊符号'
                            },
                            regexp: {
                                regexp: /^(?=[0-9a-zA-Z]+$)/,
                                message: '密码由至少8位大小写字母，数字组成，不能包含特殊符号'
                            }
                        }
                    },
                    fspassword: {
                        validators: {
                            notEmpty: {
                                message: "确认密码不能为空"
                            },
                            identical: {
                                compare: function () {
                                    return forgot_form.querySelector('[name="fspassword"]').value;
                                },
                                message: "两次密码不一致"
                            }
                        }
                    },
                },
                plugins: {
                    trigger: new Trigger(),
                    submitButton: new SubmitButton(),
                    bootstrap: new Bootstrap()
                }
            });

            //忘记密码表单提交
            this.fv3.on("core.form.valid", () => {
                var _this = this;
                //登录按钮加载动画
                const submitButton = this.$refs["kt_login_forgot_submit"];
                submitButton.classList.add("spinner", "spinner-light", "spinner-right");

                //重置密码
                updatePwd({'phone': _this.forgotphone, 'password': _this.forgotpassword, 'code': _this.forgotcode}).then(function(res){
                    if(res){
                        KTUtil.alert('', res.message, 'success');
                        _this.showForm('signin');
                        forgot_form.reset();
                    }
                });
            });
        },
        methods: {
            showForm(form) {
                this.state = form;
                if(form == 'signin'){
                    if(this.tab == 'account_login'){
                        var form_name = "kt_login_" + form + "_account_form";
                    } else {
                        var form_name = "kt_login_" + form + "_sms_form";
                    }
                } else {
                    var form_name = "kt_login_" + form + "_form";
                }
                KTUtil.animateClass(
                    KTUtil.getById(form_name),
                    "animate__animated animate__backInUp"
                );
            },
            showTab(tab) {
                this.tab = tab;
            },
            getSmsCode(type) {
                let _this = this;
                let phone = '';
                if(type == 2){
                    phone = _this.registerphone;
                } else if(type == 3) {
                    phone = _this.forgotphone;
                } else {
                    phone = _this.loginphone;
                }
                getSmsCode({'phone': phone, 'type': type}).then(function(res){
                    if(res){
                        _this.$bvToast.toast('短信验证', {
                            title: '短信验证码发送成功，请注意查收',
                            variant: 'success',
                            solid: true
                        });
                    }
                });
            }
        }
    }
</script>
